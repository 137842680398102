import React from 'react'

const Consolidate = () => {
  return (
    <div>
      CONSOLIDATED
    </div>
  )
}

export default Consolidate
