import React, { useEffect, useState } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Button,
    extendTheme,
    ChakraProvider,
    Center,
  } from '@chakra-ui/react'
import { format } from 'date-fns';

  const Summary_Table = ({ InvoiceData, selectedFilter, date , type}) => {
    const [newInvoiceData, setNewInvoiceData] = useState([...InvoiceData]);
    
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        // return d.toLocaleDateString('en-CA'); // 'en-CA' gives the "YYYY-MM-DD" format
        return format(d, 'MM/dd/yyyy')
      };


    const FormatedDate = formatDate(date);
    // console.log("Formatted date:", FormatedDate);

    const originalWeekDate = new Date(FormatedDate); // Convert the formatted date to a Date object
    originalWeekDate.setDate(originalWeekDate.getDate() + 7);
    // console.log("Original+7", originalWeekDate)

    
    const filter = localStorage.getItem("DataFilter")
    useEffect(() => {
        const filterData = JSON.parse(filter)
        // console.log("Fetched Filter from localStorage:", filter);


        // if(filter.date === ''){
        //     alert('Select a Time !!')
        //     setNewInvoiceData([...InvoiceData]);
        //     return
        // }
        if (filterData && selectedFilter === "Day") {
            const filterInvoiceData = InvoiceData?.filter((data) => {
                // console.log("Date",filterData.fromTime)
                return formatDate(data.date) === filterData.fromTime;
            });
            setNewInvoiceData(filterInvoiceData);
        } 
        else if (filterData && selectedFilter === "Week") {
            const filterInvoiceData = InvoiceData?.filter((data) => {
                const currentDate = new Date(data.date);
                
                // Parse and validate the filter dates
                const filterStartDate = new Date(filterData.fromTime);
                const filterEndDate = new Date(filterData.fromTime);
        
                if (isNaN(filterStartDate) || isNaN(filterEndDate)) {
                    console.error("Invalid date format in filterData", filterData);
                    return false;
                }
        
                // Add 6 days to the filter end date to cover a week
                filterEndDate.setDate(filterStartDate.getDate() + 6);
        
                // console.log("filterStartDate:", filterStartDate);
                // console.log("filterEndDate:", filterEndDate);
        
                return (currentDate >= filterStartDate) && (currentDate <= filterEndDate);
            });
        
            // console.log("Week filterInvoiceData:", filterInvoiceData);
            setNewInvoiceData(filterInvoiceData);
        }      
        else if (filterData && selectedFilter === "Month") {
            const filterInvoiceData = InvoiceData?.filter((data) => {
                const currentDate = new Date(data.date);
                
                // Parse and validate the filter dates
                const filterStartDate = new Date(filterData.fromTime);
                const filterEndDate = new Date(filterData.fromTime);
        
                if (isNaN(filterStartDate) || isNaN(filterEndDate)) {
                    console.error("Invalid date format in filterData", filterData);
                    return false;
                }
        
                // Add 30 days to the filter end date to cover a month
                filterEndDate.setDate(filterStartDate.getDate() + 29);
        
                // console.log("MonthStart:", filterStartDate);
                // console.log("MonthEnd:", filterEndDate);
        
                return (currentDate >= filterStartDate) && (currentDate <= filterEndDate);
            });
        
            // console.log("Week filterInvoiceData:", filterInvoiceData);
            setNewInvoiceData(filterInvoiceData);
        }
        else if (filterData && selectedFilter === "Custome") {
            const filterInvoiceData = InvoiceData?.filter((data) => {
                const currentDate = new Date(data.date);
                
                // Parse and validate the filter dates
                const filterStartDate = new Date(filterData.fromTime);
                const filterEndDate = new Date(filterData.toTime);
        
                if (isNaN(filterStartDate) || isNaN(filterEndDate)) {
                    console.error("Invalid date format in filterData", filterData);
                    return false;
                }
        
                // console.log("MonthStart:", filterStartDate);
                // console.log("MonthEnd:", filterEndDate);
        
                return (currentDate >= filterStartDate) && (currentDate <= filterEndDate);
            });
        
            // console.log("Week filterInvoiceData:", filterInvoiceData);
            setNewInvoiceData(filterInvoiceData);
        }
         else {
            setNewInvoiceData([...InvoiceData]);
        }
    }, [InvoiceData, selectedFilter, filter]);

    // console.log("Summary Invoice: ", InvoiceData);
    // console.log("newInvoiceData", newInvoiceData);

  return (
    <div>
        <TableContainer>
            <Table variant='striped' sx={{
                    "tbody tr:nth-of-type(odd)": {
                        bg: "#ffffff !important" // Change to white
                    },
                    "tbody td": {
                        bg: "transparent !important" // Change to white
                    },
                    "tbody tr:nth-of-type(even)": {
                        bg: "#c9e6fa !important" // Light blue shade
                    },
                    border: "none !important" // Remove table border
                }} border={'none !important'}>
                <Thead>
                {newInvoiceData && type === 'Sales' && 
                    <Tr >
                        <Th textAlign='center' >Employee Name</Th>
                        <Th textAlign='center'>Phone</Th>
                        <Th textAlign='center'>Date</Th>
                        <Th isNumeric textAlign='center'>Discount</Th>
                        <Th isNumeric textAlign='center'>Total Price</Th>
                        <Th isNumeric textAlign='center'>Paid Amount</Th>
                        <Th isNumeric textAlign='center'>Balance Amount</Th>
                        <Th textAlign='center'>Payment Type</Th>
                    </Tr>
                }
                {newInvoiceData && type === 'Membership' && 
                    <Tr>
                        <Th textAlign='center'>Client Name</Th>
                        <Th textAlign='center'>Phone</Th>
                        <Th textAlign='center'>DOB</Th>
                        <Th  textAlign='center'>Email</Th>
                        <Th  textAlign='center'>Gender</Th>
                        <Th  textAlign='center'>Membership</Th>
                        <Th  textAlign='center'>Spent</Th>
                        <Th textAlign='center'>Balance</Th>
                    </Tr>
                }
                
                {newInvoiceData && type === 'Staff' && 
                    <Tr>
                        <Th textAlign='center'>Staff Name</Th>
                        <Th textAlign='center'>Phone</Th>
                        <Th textAlign='center'>Category</Th>
                        <Th textAlign='center'>Experience</Th>
                        <Th  textAlign='center'>DOB</Th>
                        <Th  textAlign='center'>Salary</Th>
                        <Th  textAlign='center'>Sales</Th>
                    </Tr>
                }
                
                {newInvoiceData && type === 'Invoice' && 
                    <Tr>
                        <Th textAlign='center'>Employee Name</Th>
                        <Th textAlign='center'>Phone</Th>
                        <Th textAlign='center'>Date</Th>
                        <Th textAlign='center'>Invoice Type</Th>
                        <Th isNumeric textAlign='center'>Discount</Th>
                        <Th isNumeric textAlign='center'>Total Price</Th>
                        <Th isNumeric textAlign='center'>Paid Amount</Th>
                        <Th isNumeric textAlign='center'>Balance Amount</Th>
                        <Th textAlign='center'>Payment Type</Th>
                    </Tr>
                }
                
                {newInvoiceData && type === 'Inventory' && 
                    <Tr>
                        <Th textAlign='center'>Product Name</Th>
                        <Th textAlign='center'>Category</Th>
                        <Th textAlign='center'>Type</Th>
                        <Th textAlign='center'>Unit</Th>
                        <Th textAlign='center'>Price</Th>
                        <Th textAlign='center'>Size</Th>
                        <Th textAlign='center'>Quantity</Th>
                        <Th textAlign='center'>Brand</Th>
                    </Tr>
                }
                </Thead>
                <Tbody>
                    {newInvoiceData && type === 'Sales' && (
                        newInvoiceData.length != 0 ? newInvoiceData.map((item, index) => {
                            const discount_price = Number(item?.discount_price) || 0;
                            const balance = Number(item?.balance) || 0;
                            const total_price = Number(item?.total_price) || 0;
                            const paid_amount = Number(item?.paid_amount) || 0;

                            return (
                                <Tr key={index}>
                                    <Td textAlign='center'>{item.client_name || 'NA'}</Td>
                                    <Td textAlign='center'>{item.client_number || 'NA'}</Td>
                                    <Td textAlign='center'>{item.date || 'NA'}</Td>
                                    <Td textAlign='center'>{discount_price} %</Td>
                                    <Td textAlign='center'>Rs. {total_price}</Td>
                                    <Td textAlign='center'>Rs. {paid_amount}</Td>
                                    <Td textAlign='center'>Rs. {balance}</Td>
                                    <Td textAlign='center'>{item.payment_type || 'NA'}</Td>
                                </Tr>
                                    );
                                }) : (
                                    <Center color={'red.500'}>No Data for this Filter !!</Center>
                                )
                    )}

                    {newInvoiceData && type === 'Membership' && (newInvoiceData.length != 0 ? newInvoiceData?.map((item, index)=>{
                        const membership = Number(item.membership) || 0;
                        const spend = Number(item.spent) || 0;
                        const balance = membership - spend;

                        return(
                            <Tr key={index}>
                                <Td textAlign='center'>{item.name ? item.name : 'NA'}</Td>
                                <Td textAlign='center'>{item.mobile_number ? item.mobile_number : 'NA'}</Td>
                                <Td textAlign='center'>{item.dob ? item.dob : 'NA'}</Td>
                                <Td textAlign='center'>{item.email ? item.email : 'NA'}</Td>
                                <Td textAlign='center'>{item.gender ? item.gender : '0'}</Td>
                                <Td textAlign='center'>Rs. {membership}</Td>
                                <Td textAlign='center'>Rs. {spend}</Td>
                                <Td textAlign='center'>Rs. {balance}</Td>
                            </Tr>
                        )
                    }) : (
                                    <Center color={'red.500'}>No Data for this Filter !!</Center>
                                )
                    )}
                    
                    {newInvoiceData && type === 'Staff' &&  (newInvoiceData.length != 0 ?  newInvoiceData?.map((item, index)=>{
                        const salary = Number(item?.salary) || 0;
    
                        return(
                            <Tr key={index}>
                                <Td textAlign='center'>{item.name ? item.name : 'NA'}</Td>
                                <Td textAlign='center'>{item.ph_number ? item.ph_number : 'NA'}</Td>
                                <Td textAlign='center'>{item.category ? item.category : 'NA'}</Td>
                                <Td textAlign='center'>{item.experience ? item.experience : 'NA'} years</Td>
                                <Td textAlign='center'>{item.date_of_birth ? item.date_of_birth : 'NA'}</Td>
                                <Td textAlign='center'>RS. {item.salary ? Math.floor(salary) : '0'}</Td>
                                <Td textAlign='center'>Rs. {item.sales ? item.sales : '0'}</Td>
                            </Tr>
                        )
                    }) : (
                                    <Center color={'red.500'}>No Data for this Filter !!</Center>
                                )
                    )}

                    {newInvoiceData && type === 'Invoice' &&  (newInvoiceData.length != 0 ?  newInvoiceData?.map((item, index)=>{
                        return(
                        <Tr key={index}>
                            <Td textAlign='center'>{item.client_name ? item.client_name : 'NA'}</Td>
                            <Td textAlign='center'>{item.client_number ? item.client_number : 'NA'}</Td>
                            <Td textAlign='center'>{item.date ? item.date : 'NA'}</Td>
                            <Td textAlign='center'>{item.invoice_type ? item.invoice_type : 'NA'}</Td>
                            <Td textAlign='center'>{item.discount_price && item.discount_price!=='0.00' ? item.discount_price : '0'} %</Td>
                            <Td textAlign='center'>Rs. {item.total_price && item.total_price!=='0.00' ? Math.floor(item.total_price) : '0'}</Td>
                            <Td textAlign='center'>RS. {item.paid_amount && item.paid_amount!== '0.00' ? Math.floor(item.paid_amount) : '0'}</Td>
                            <Td textAlign='center'>RS. {item.balance && item.balance!== '0.00' ? Math.floor(item.balance ) : '0'}</Td>
                            <Td textAlign='center'>{item.payment_type ? item.payment_type : 'NA'}</Td>
                        </Tr>
                    )
                    }) : (
                                    <Center color={'red.500'}>No Data for this Filter !!</Center>
                                )
                    )}
                    
                    {newInvoiceData && type === 'Inventory' &&  (newInvoiceData.length != 0 ?   newInvoiceData?.map((item, index)=>{
                        return(
                        <Tr key={index}>
                            <Td textAlign='center'>{item.name ? item.name : 'NA'}</Td>
                            <Td textAlign='center'>{item.category ? item.category : 'NA'}</Td>
                            <Td textAlign='center'>{item.type ? item.type.toUpperCase() : 'NA'}</Td>
                            <Td textAlign='center'>{item.unit ? item.unit : 'NA'}</Td>
                            <Td textAlign='center'>Rs. {item.price && item.price!=='0.00' ? Math.floor(item.price) : '0'}</Td>
                            <Td textAlign='center'>{item.size && item.size!== '0.00' ? item.size : '0'}</Td>
                            <Td textAlign='center'>{item.quantity && item.quantity!== '0.00' ? item.quantity : '0'}</Td>
                            <Td textAlign='center'>{item.brand && item.brand!== '0.00' ? item.brand : '0'}</Td>
                        </Tr>
                    )
                    }) : (
                                    <Center color={'red.500'}>No Data for this Filter !!</Center>
                                )
                    )}
                    

                </Tbody>
            </Table>

        </TableContainer>
    </div>
  )
}

export default Summary_Table
