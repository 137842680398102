import React from 'react'

const Trends = () => {
  return (
    <div>
      TRENDS
    </div>
  )
}

export default Trends