import { Box, useRadio } from '@chakra-ui/react'
import React from 'react'

const RadioCard = (props) => {
        const { getInputProps, getRadioProps } = useRadio(props)
      
        const input = getInputProps()
        const checkbox = getRadioProps()
      
        return (
          <Box as='label'>
            <input {...input} />
            <Box
              {...checkbox}
              cursor='pointer'
              borderWidth='1px'
              borderRadius='md'
              boxShadow='md'
              _checked={{
                bg: 'teal.600',
                color: 'white',
                borderColor: 'teal.600',
              }}
              _focus={{
                boxShadow: 'outline',
              }}
              px={5}
              py={2}
              borderColor={'gray.400'}
            >
              {props.children}
            </Box>
          </Box>
        )
      }


export default RadioCard
