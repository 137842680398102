import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import axios from "axios";
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import PieChart from "./PieChart";
import Summary_Table from "./Summary_Table";
import { ChevronDownIcon } from "@chakra-ui/icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ShowReports = ({selectedFilter, date, activeLink}) => { 
  // const dbpath1 = "http://localhost/backend/";
  const dbpath1 = "https://krubsalon.royalswebtech.com/backend/";
  const [Edata, setEData] = useState([]);
  const [Idata, setIData] = useState([]);
  const [Cdata, setCData] = useState([]);
  const [InvoiceData, setInvoiceData] = useState([]);
  const [InventoryData, setInventoryData] = useState([]);

  const [labels, setLabels] = useState([]);
  const [tableLabels, setTableLabels] = useState([]);
  const [tableLabels1, setTableLabels1] = useState([]);
  const [invoiceLabels, setInvoiceLabels] = useState([]);
  const [inventoryLabels, setInventoryLabels] = useState([]);

  const [dataValuesLabels, setDataValuesLabels] = useState([]);
  const [dataValuesLabelsSales, setDataValuesLabelsSales] = useState([]);
  
  const [staffSalleryDataValues, setStaffSalleryDataValues] = useState([]);
  const [staffSalesDataValues, setStaffSalesDataValues] = useState([]);
  
  const [invoiceTotalDataValues, setInvoiceTotalDataValues] = useState([]);
  const [invoicePaidDataValues, setInvoicePaidDataValues] = useState([]);
  const [invoiceBalanceDataValues, setInvoiceBalanceDataValues] = useState([]);

  const [inventoryPriceDataValues, setInventoyPriceDataValues] = useState([]);
  const [inventorySizeDataValues, setInventoySizeDataValues] = useState([]);
  const [inventoryQuantityDataValues, setInventoyQuantityDataValues] = useState([]);

  const [pieChartOptions, setPieChartOptons] = useState([])
  const [invoicePieChartOptions, setInvoicePieChartOptons] = useState([])
  const [inventoryPieChartOptions, setInventoryPieChartOptons] = useState([])

  const loadEmployeeData = async () => {
    try {
      const response = await axios.get(`${dbpath1}getEmployeedata.php`);
      const Employeedata = response.data.phpresult;
      if (Array.isArray(Employeedata)) {
        setEData(Employeedata);
        const labelData = Employeedata?.map((emp) => emp.name); // Replace 'labelProperty' with the actual property name
        setLabels(labelData);

        console.log("Labels_____+++", labelData)
        const dataValues = Employeedata?.map((emp) => Number(emp.salary)); // Replace 'labelProperty' with the actual property name
        const dataValuesSpend = Employeedata?.map((emp) => Number(emp.sales)); // Replace 'labelProperty' with the actual property name
        setPieChartOptons({ 'Salary': dataValues, 'Sales': dataValuesSpend });

        setStaffSalleryDataValues(dataValues);
        setStaffSalesDataValues(dataValuesSpend);
        console.log("Employee Data:", Employeedata);
      } else {
        console.error("Expected an array but got:", Employeedata);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  
  const loadClientData = async () => {
    try {
      const response = await axios.get(`${dbpath1}getClientData.php`);
      const ClientData = response.data.phpresult;
      if (Array.isArray(ClientData)) {
        setCData(ClientData);
        const labelData = ClientData?.map((client) => client.name); // Replace 'labelProperty' with the actual property name
        const dataValues = ClientData?.map((emp) => Number(emp.membership)); // Replace 'labelProperty' with the actual property name
        const dataValuesSpend = ClientData?.map((emp) => Number(emp.spent)); // Replace 'labelProperty' with the actual property name
        setDataValuesLabels(dataValues);
        setDataValuesLabelsSales(dataValuesSpend);
        setTableLabels(labelData);
        console.log("Client Data:", ClientData);
      } else {
        console.error("Expected an array but got:", ClientData);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  
  
  const loadSalesData = async () => {
    try {
      const response = await axios.get(`${dbpath1}getInvoicedata.php`);
      const InvoiceData = response.data.filter((data)=>{return data.invoice_type === 'Products'});
      if (Array.isArray(InvoiceData)) {
        setIData(InvoiceData);
        const labelData = InvoiceData.map((emp) => emp.name); // Replace 'labelProperty' with the actual property name
        setTableLabels1(labelData);
        console.log("Sales Data:", InvoiceData);
      } else {
        console.error("Expected an array but got:", InvoiceData);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  
  const loadInvoiceData = async () => {
    try {
      const response = await axios.get(`${dbpath1}getInvoicedata.php`);
      const InvoiceData = response.data;
      if (Array.isArray(InvoiceData)) {
        setInvoiceData(InvoiceData);
        const labelData = InvoiceData.map((emp) => emp.client_name); // Replace 'labelProperty' with the actual property name
        setInvoiceLabels(labelData);

        const DataValue1 = InvoiceData.map((emp) => emp.total_price);
        const DataValue2 = InvoiceData.map((emp) => emp.paid_amount);
        const DataValue3 = InvoiceData.map((emp) => emp.balance);

        setInvoiceTotalDataValues(DataValue1);
        setInvoicePaidDataValues(DataValue2);
        setInvoiceBalanceDataValues(DataValue3);

        setInvoicePieChartOptons({ 'Total': DataValue1, 'Paid': DataValue2, 'Balance': DataValue3 });

        console.log("Invoice Data:", InvoiceData);
      } else {
        console.error("Expected an array but got:", InvoiceData);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  
  const loadInventoryData = async () => {
    try {
      // const response = await axios.get("http://localhost/backend/getProducts.php")
      const response = await axios.get("https://krubsalon.royalswebtech.com/backend/getProducts.php")
      const InvoiceData = response.data.phpresult;
      // console.log("inventory:   "+"http://localhost/backend/getProducts.php")
      console.log("inventory:   "+"https://krubsalon.royalswebtech.com/backend/getProducts.php")
      if (Array.isArray(InvoiceData)) {
        setInventoryData(InvoiceData);

        const labelData = InvoiceData.map((emp) => emp.name); // Replace 'labelProperty' with the actual property name
        setInventoryLabels(labelData);
        
        const data1 = InvoiceData.map((emp) => emp.price); // Replace 'labelProperty' with the actual property name
        const data2 = InvoiceData.map((emp) => emp.quantity); // Replace 'labelProperty' with the actual property name
        const data3 = InvoiceData.map((emp) => emp.size); // Replace 'labelProperty' with the actual property name
        
        setInventoyPriceDataValues(data1);
        setInventoyQuantityDataValues(data2);
        setInventoySizeDataValues(data3);

        setInventoryPieChartOptons({"Price": data1, "Quantity": data2, "Size": data3})
        
        console.log("Inventory Data:", InvoiceData);
      } else {
        console.error("Expected an array but got:", InvoiceData);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    if (selectedFormate === 'Table') {
      loadSalesData();
      loadClientData();
      loadInvoiceData();
      loadInventoryData();
    } if (selectedFormate === 'Bar Chart') {
      loadEmployeeData();
    } else {
      loadEmployeeData();
    }
  }, []);

  const dataValues = Edata.map(data => data.sales); // Replace 'valueProperty' with the actual property name
  // console.log("data Values: ", dataValues);

  const [selectedFormate, setSelectedFormate] = useState('Table');
  const [downloadFormate, setDownloadFormate] = useState('.PDF');

  const renderContent = (selectedFormate, summaryType) => {
    switch (selectedFormate) {
      case "Table":
        switch(summaryType) {
          case "Sales":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'30px', marginBottom:'20px'}}
                >
                  Sales Summary
                </Button>
                <Summary_Table InvoiceData={Idata} selectedFilter={selectedFilter} type={'Sales'}/>
              </>
            );
          case "Membership":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'80px', marginBottom:'20px' }}
                >
                  Membership Summary
                </Button>
                <Summary_Table InvoiceData={Cdata} selectedFilter={selectedFilter} date={date} type={'Membership'}/>
              </>
            );
          
            case "Staff":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'80px', marginBottom:'20px' }}
                >
                  Staff Summary
                </Button>
                <Summary_Table InvoiceData={Edata} selectedFilter={selectedFilter} date={date} type={'Staff'}/>
              </>
            );

            case "Invoice":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'80px', marginBottom:'20px'}}
                >
                  Invoice Summary
                </Button>
                <Summary_Table InvoiceData={InvoiceData} selectedFilter={selectedFilter} type={'Invoice'}/>
              </>
            );
            
            case "Inventory":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'80px', marginBottom:'20px'}}
                >
                  Inventory Data
                </Button>
                <Summary_Table InvoiceData={InventoryData} selectedFilter={selectedFilter} type={'Inventory'}/>
              </>
            );

          default:
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Summary Type Not Found !!
              </div>
            );
        }
      case "Pie Chart":
        switch(summaryType) {
          case "Sales":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'80px', marginBottom:'20px' }}
                >
                  Sales Summary
                </Button>
                <PieChart labels={labels} dataValues={dataValues} type={'Sales'}/>
              </>
            );
          case "Membership":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Membership Summary
                </Button>
                <PieChart labels={tableLabels} dataValues={dataValuesLabels} type={'Membership'}/>
              </>
            );
          
            case "Staff":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Staff Summary
                </Button>
                <PieChart labels={labels} dataValues={staffSalleryDataValues} dataValues1={staffSalesDataValues} options={pieChartOptions} type={'Staff'}/>
              </>
            );
            case "Invoice":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Invoice Summary
                </Button>
                <PieChart labels={invoiceLabels} dataValues={staffSalleryDataValues} dataValues1={staffSalesDataValues} options={invoicePieChartOptions} type={'Invoice'}/>
              </>
            );
            case "Inventory":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Inventory Data
                </Button>
                <PieChart labels={inventoryLabels} dataValues={inventoryPriceDataValues} dataValues1={inventoryQuantityDataValues} options={inventoryPieChartOptions} type={'Inventory'}/>
              </>
            );
          default:
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Summary Type Not Found !!
              </div>
            );
        }
      case "Bar Chart":
        switch(summaryType) {
          case "Sales":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Sales Summary
                </Button>
                <BarChart labels={labels} dataValues={dataValues} labelName={'No. of Sales'} dataValues2={[]}/>
              </>
            );
          case "Membership":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold',  marginTop:'50px' }}
                >
                  Membership Summary
                </Button>
                <BarChart labels={tableLabels} dataValues={dataValuesLabels} dataValues2={dataValuesLabelsSales} labelName={'Membership Amount'} labelName2={'Spended Amount'}/>
              </>
            );
          
            case "Staff":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'50px' }}
                >
                  Staff Summary
                </Button>
                <BarChart labels={labels} dataValues={staffSalleryDataValues} dataValues2={staffSalesDataValues} labelName={'Salary'} labelName2={'Sales'} />
              </>
            );

            case "Invoice":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'50px' }}
                >
                  Invoice Summary
                </Button>
                <BarChart labels={invoiceLabels} dataValues={invoiceTotalDataValues} dataValues2={invoicePaidDataValues} dataValues3={invoiceBalanceDataValues} labelName={'Toatl Amount'} labelName2={'Amount Paid'} labelName3={'Balance Amount'}/>
              </>
            );
            
            case "Inventory":
            return (
              <>
                <Button
                  color={"black"}
                  borderRadius={"none"}
                  opacity={0.5}
                  style={{ fontSize: '20px', fontWeight: 'bold', marginTop:'50px' }}
                >
                  Inventory Data
                </Button>
                <BarChart labels={inventoryLabels} dataValues={inventoryPriceDataValues} dataValues2={inventoryQuantityDataValues} dataValues3={inventorySizeDataValues} labelName={'Price'} labelName2={'Quantity'} labelName3={'Size'}/>
              </>
            );

          default:
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Summary Type Not Found !!
              </div>
            );
        }
      default:
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Format Not Found !!
          </div>
        );
    }
  };

const showActiveContent = ({activeLink, selectedFormate}) => {
  switch(activeLink){
    case 'All':
      return (
        <>
          {renderContent(selectedFormate, "Sales")}
          {renderContent(selectedFormate, "Membership")}
          {renderContent(selectedFormate, "Staff")}
          {renderContent(selectedFormate, "Invoice")}
          {renderContent(selectedFormate, "Inventory")}
        </>
      );
    default:
      return renderContent(selectedFormate, activeLink);
  }
};



const printDocument = () => {
  const input = document.getElementById("PrintData");
  
  html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;
      
      // Convert HTML height to mm since jsPDF uses mm
      const htmlWidth = input.clientWidth;
      const htmlHeight = input.clientHeight;
      const ratio = htmlHeight / htmlWidth;
      const pdfCanvasHeight = pdfWidth * ratio;

      let position = 0;

      // Calculate the height of each page and the number of pages required
      while (position < htmlHeight) {
          pdf.addImage(
              imgData,
              'PNG',
              0,
              position === 0 ? 0 : -pdfCanvasHeight + pdfHeight,
              pdfWidth,
              pdfCanvasHeight
          );
          position += pdfCanvasHeight;
          if (position < htmlHeight) {
              pdf.addPage();
          }
      }

      pdf.save(`Reports_${activeLink}_${selectedFormate}.pdf`);
  });
};


  return (
  <div>

    <Box
        color={"black"}
        w={"full"}
        height={"fit-content"}
        display={'flex'}
        px={2}
        justifyContent={'end'}
      >
          <Menu>
            <MenuButton
              marginLeft={'6px'}
              px={4}
              py={2}
              transition='all 0.2s'
              borderRadius='md'
              borderWidth='1px'
              _hover={{ bg: 'gray.400' }}
              _expanded={{ bg: 'blue.400' }}
              _focus={{ boxShadow: 'outline' }}
              _placeholder={'Pie Chart'}
            >
              {downloadFormate}{" "}
              
              <ChevronDownIcon marginLeft={'4px'}/>
            </MenuButton>
            <MenuList color={'white'} overflowY={'auto'}>
                  <MenuItem name='Pei Chart' value={'.CSV'} onClick={(e)=>{setDownloadFormate(e.target.value)}}>.CSV</MenuItem>

                  <MenuItem value={".PDF"} onClick={(e)=>{setDownloadFormate(e.target.value)}}>.PDF</MenuItem>
            </MenuList>
          </Menu>
          
          <Menu>
            <MenuButton
              marginLeft={'6px'}
              px={4}
              py={2}
              transition='all 0.2s'
              borderRadius='md'
              borderWidth='1px'
              _hover={{ bg: 'gray.400' }}
              _expanded={{ bg: 'blue.400' }}
              _focus={{ boxShadow: 'outline' }}
              _placeholder={'Pie Chart'}
            >
              {selectedFormate}{" "}
              
              <ChevronDownIcon marginLeft={'4px'}/>
            </MenuButton>
            <MenuList color={'white'} overflowY={'auto'}>
                  <MenuItem name='Pei Chart' value={'Pie Chart'} onClick={(e)=>{setSelectedFormate(e.target.value)}}>Pie Chart</MenuItem>
                  <MenuItem value={"Table"} onClick={(e)=>{setSelectedFormate(e.target.value)}}>Table</MenuItem>
                  <MenuItem value={"Bar Chart"} onClick={(e)=>{setSelectedFormate(e.target.value)}}>Bar Chart</MenuItem>
            </MenuList>
          </Menu>
      </Box>


    {/* Code For the Report Generating Section in Different Formate */}
    <div id="PrintData" style={{width:'100'}}>
      <Box
          color={"black"}
          w={"full"}
          height={"100%"}
          px={2}
          top={0}
          marginBottom={'20px'}
        >
          {/* {renderContent(selectedFormate)} */}
          {showActiveContent({activeLink, selectedFormate})}
        </Box>
    </div>

      <Box
        color={"black"}
        w={"full"}
        height={"fit-content"}
        display={'flex'}
        px={2}
        justifyContent={'end'}
        margin={'100px 0 20px 0'}
      >
        {downloadFormate && downloadFormate === '.PDF' ? 
          <Button colorScheme='green' onClick={printDocument}>Download PDF File</Button> : <Button colorScheme='blue'>Download CSV File</Button>
        }

        

      </Box>
  </div>);
};

export default ShowReports;
