import { Box, Divider } from '@chakra-ui/react';
import {
    Center,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Select,
    HStack,
    VStack,
    Heading,
    Text,
    Button,
    Checkbox,
    TableContainer,
    TableCaption,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex,
    FormLabel,
    Avatar,
    Tag,
  } from "@chakra-ui/react";

import React, { useEffect, useState } from 'react'


const Detail_Section = () => {
    const [employeeData, setEmployeeData] = useState([])
    const [role, setRole] = useState([])
    useEffect(() => {
        const role = localStorage.getItem('EmpRole');
        setRole(role);

        const data = localStorage.getItem('EmployeeData');
        const Parse_emp = JSON.parse(data);
        setEmployeeData(Parse_emp);
    }, []);
    
    useEffect(() => {
        console.log("EmployeeData", employeeData);
    }, [employeeData]);

  return (
    <div>
    {/* <Box borderWidth="1px" borderRadius="lg" p="4" boxShadow="lg" color="blackAlpha.500">
      <Text fontWeight="bold" fontSize="xl">{`Employee ID: ${employeeData?.id}`}</Text>
      <Text>{`Name: ${employeeData?.name}`}</Text>
      <Text>{`Phone Number: ${employeeData?.ph_number}`}</Text>
      <Text>{`Type: ${employeeData?.category}`}</Text>
      <Text>{`Experience: ${employeeData?.experience}`}</Text>
      <Text>{`Date of Birth: ${employeeData?.date_of_birth}`}</Text>
      <Text>{`Salary: ${employeeData?.salary}`}</Text>
      <Text>{`Sales: ${employeeData?.sales}`}</Text>

      <Divider my="2" />

      <Text fontWeight="bold">Other Details</Text>
      <Text>{`Balance: ${employeeData?.Balance}`}</Text>
      <Text>{`Other: ${employeeData?.OtherDetails}`}</Text>
    </Box>    */}

    <Box flex={"50%"} justifyContent={"center"} marginTop={"30px"}>
        <Box display={"flex"} flexDirection={"column"} h={"91vh"}>
        <Heading
        color={"black"}
        fontFamily={"sans-serif"}
        fontSize={"25px"}
        mb={4}
      >
        <Center>{`${role} Details`}</Center></Heading>
            <Box flex={"30%"}
     boxShadow={'2px 5px 5px 0px #d2d2d2'}
     display={"flex"}
     justifyContent={"center"}
     width={"100%"}
     >
     
              <HStack
                maxW={"75%"}
                width={"100%"}
                height={"max-content"}
                padding={"50px"}
                // boxShadow={"2px 5px 5px 0px #d2d2d2"}
                p={4}
                mt={6}
                justifyContent={"space-between"}
                border={"3px dotted lightgray"}
              >
              
                <Box display={'flex'} flexDirection={'column'} paddingLeft={"30px"}> 
                  <Avatar size={"xl"} />
                  <Center display={'flex'} flexDirection={'column'} justifyContent={'start'} gap={'0'}>
                    <Tag colorScheme='linkedin' mt={4} color={'linkedin.800'}>{`${employeeData?.name}`}</Tag>
                    <br />
                    <Tag textAlign={'Center'} colorScheme='linkedin' mt={-25} color={'linkedin.800'}>{`(ID - ${employeeData?.id})`}</Tag>
                  </Center>
                </Box>

                <div style={{width:'70%', marginLeft:'100px', display:'flex', flexDirection:'column', margin:'30px 0'}}>

                <Heading
                    color={"gray"}
        fontFamily={"sans-serif"}
        fontSize={"20px"}
        mb={4}
                    >Personal Details</Heading>

                  <div style={{display:'flex', justifyContent:'start', gap:'100px', width:'100%'}}>
                  
                    <VStack
                      color={"black"}
                      fontSize={"md"}
                      alignItems={"flex-start"}
                      fontWeight={"semibold"}
                    >

                      <Text>Name : <span style={{fontWeight:'bold', color:'gray', marginLeft:'4px'}}>{employeeData?.name}</span></Text>

                      <Text>Type : <span style={{fontWeight:'bold', color:'gray', marginLeft:'4px'}}>{employeeData?.category}</span></Text>

                      <Text>Phone Number : <span style={{fontWeight:'bold', color:'gray', marginLeft:'4px'}}>{employeeData?.ph_number}</span></Text>
                    </VStack>
                    <VStack
                      color={"black"}
                      alignItems={"flex-start"}
                      fontSize={"md"}
                      fontWeight={"semibold"}
                    >
                      <Text>Experience : <span style={{fontWeight:'bold', color:'gray', marginLeft:'4px'}}>{employeeData?.experience} years</span></Text>

                      <Text style={{width:'100%'}}>Date of Birth : <span style={{fontWeight:'bold', color:'gray', marginLeft:'4px'}}>{employeeData?.date_of_birth}</span></Text>

                      {/* <Text>Salary : <span style={{fontWeight:'bold', color:'green', marginLeft:'4px',}}>{employeeData?.salary}/-</span></Text>
                      
                      <Text>Sales : <span style={{fontWeight:'bold', color:'#dd00ff', marginLeft:'4px',}}>{employeeData?.sales}</span></Text> */}
                    </VStack>{" "}
                  </div>

                  <hr style={{borderTop:'1px solid #ccc', margin:'25px 0'}}/>
                
                <Heading
                    color={"gray"}
        fontFamily={"sans-serif"}
        fontSize={"20px"}
        mb={4}
                    >Sales Details</Heading>

                  <div style={{display:'flex', justifyContent:'start', gap:'100px', width:'100%'}}>

                    <VStack
                      color={"black"}
                      alignItems={"flex-start"}
                      fontSize={"md"}
                      fontWeight={"semibold"}
                    >

                      <Text>Salary : <span style={{fontWeight:'bold', color:'green', marginLeft:'4px',}}>{employeeData?.salary}/-</span></Text>
                      
                    </VStack>
                    <VStack
                      color={"black"}
                      alignItems={"flex-start"}
                      fontSize={"md"}
                      fontWeight={"semibold"}
                    >
                    <Text>Sales : <span style={{fontWeight:'bold', color:'#dd00ff', marginLeft:'4px',}}>{employeeData?.sales}/-</span></Text>
                      
                    </VStack>{" "}
                  </div>
                </div>
              </HStack>
            </Box>
            
            </Box>
          </Box>
    </div>
  )
}

export default Detail_Section
