import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const BarChart = ({ labels = [], dataValues = [], labelName, labelName2, dataValues2 = [], dataValues3 = [], labelName3 }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log("Labels: ", labels);
    console.log("Data Values: ", dataValues);
    console.log("Data Values 2: ", dataValues2);
    console.log("Data Values 3: ", dataValues3);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: labelName,
          data: dataValues,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: labelName2,
          data: dataValues2,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: labelName3,
          data: dataValues3,
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        }
      ].filter(dataset => dataset.data.length > 0), // Filter out empty datasets
    };

    setData(prevData => {
      if (JSON.stringify(prevData) !== JSON.stringify(chartData)) {
        return chartData;
      }
      return prevData;
    });
  }, [labels, dataValues, dataValues2, dataValues3, labelName, labelName2, labelName3]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '400px' }}>
      {data ? (
        <Bar options={options} data={data} />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
}

export default BarChart;
