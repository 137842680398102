import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Center, HStack, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "./RadioCard/RadioCard";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ labels, dataValues, type, options }) => {
  const validData =
    Array.isArray(labels) &&
    Array.isArray(dataValues) &&
    labels.length === dataValues.length;

  const generateColors = (numColors) => {
    const colors = [];
    const baseColors = [
      "rgba(255, 99, 132, 0.2)", // Red
      "rgba(54, 162, 235, 0.2)", // Blue
      "rgba(255, 206, 86, 0.2)", // Yellow
      "rgba(75, 192, 192, 0.2)", // Green
      "rgba(153, 102, 255, 0.2)", // Purple
      "rgba(255, 159, 64, 0.2)", // Orange
      "rgba(201, 203, 207, 0.2)", // Grey
      "rgba(140, 214, 123, 0.2)", // Light Green
      "rgba(255, 99, 71, 0.2)", // Tomato
      "rgba(119, 221, 119, 0.2)", // Pastel Green
      "rgba(255, 140, 0, 0.2)", // Dark Orange
      "rgba(106, 90, 205, 0.2)", // Slate Blue
      "rgba(123, 104, 238, 0.2)", // Medium Slate Blue
      "rgba(147, 112, 219, 0.2)", // Medium Purple
      "rgba(60, 179, 113, 0.2)", // Medium Sea Green
      "rgba(30, 144, 255, 0.2)", // Dodger Blue
      "rgba(255, 20, 147, 0.2)", // Deep Pink
      "rgba(210, 105, 30, 0.2)",
    ];

    for (let i = 0; i < numColors; i++) {
      colors.push(baseColors[i % baseColors.length]);
    }

    return colors;
  };

  const generateBorderColors = (colors) => {
    return colors.map((color) => color.replace("0.2", "1"));
  };

  const getLabelByType = (type) => {
    switch (type) {
      case "Sales":
        return "No. of Sales Rs.";
      case "Staff":
        return "Salary";
      case "Membership":
        return "Membership Amount";
      default:
        return "Unknown Type";
    }
  };

  const [optionValue, setOptionValue] = useState("Salary");
  const [chartData, setChartData] = useState({
    labels: validData ? labels : [],
    datasets: [
      {
        label: getLabelByType(type),
        data: validData ? dataValues : [],
        backgroundColor: validData ? generateColors(labels.length) : [],
        borderColor: validData ? generateBorderColors(generateColors(labels.length)) : [],
        borderWidth: 1,
      },
    ],
  });

  const handleOptionValue = (value) => {
    setOptionValue(value);
  };

  useEffect(() => {
    if (type === "Staff" && options) {
      const newData = {
        labels: labels,
        datasets: [
          {
            label: optionValue === "Salary" ? "Salary" : "Sales",
            data: optionValue === "Salary" ? options.Salary : options.Sales,
            backgroundColor: generateColors(labels.length),
            borderColor: generateBorderColors(generateColors(labels.length)),
            borderWidth: 1,
          },
        ],
      };
      setChartData(newData);
    }
  }, [optionValue, options, labels, type]);

  const optionsMap = options ? Object.keys(options) : [];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: optionsMap[0],
    onChange: handleOptionValue,
  });

  const group = getRootProps();

  const [optionValueInvoice, setOptionValueInvoice] = useState("Total");
  const [chartDataInvoice, setChartDataInvoice] = useState({
    labels: validData ? labels : [],
    datasets: [
      {
        label: getLabelByType(type),
        data: validData ? dataValues : [],
        backgroundColor: validData ? generateColors(labels.length) : [],
        borderColor: validData ? generateBorderColors(generateColors(labels.length)) : [],
        borderWidth: 1,
      },
    ],
  });

  const handleOptionValueInvoice = (value) => {
    setOptionValueInvoice(value);
  };

  console.log("OPTIONS", options)

  useEffect(() => {
    if (type === "Invoice" && options) {
      let dataLabel = "";
      let dataSource = [];

      switch (optionValueInvoice) {
        case "Total":
          dataLabel = "Total";
          dataSource = options.Total;
          break;
        case "Paid":
          dataLabel = "Paid";
          dataSource = options.Paid;
          break;
        case "Balance":
          dataLabel = "Balance";
          dataSource = options.Balance;
          break;
        default:
          dataLabel = "Total";
          dataSource = options.Total;
      }

      const newData = {
        labels: labels,
        datasets: [
          {
            label: dataLabel,
            data: dataSource,
            backgroundColor: generateColors(labels.length),
            borderColor: generateBorderColors(generateColors(labels.length)),
            borderWidth: 1,
          },
        ],
      };
      setChartData(newData);
    }
  }, [optionValueInvoice, options, labels, type]);

  const optionsMapInvoice = options ? Object.keys(options) : [];
  const { getRootProps: getRootPropsInvoice, getRadioProps: getRadioPropsInvoice } = useRadioGroup({
    name: "framework",
    defaultValue: optionsMapInvoice[0],
    onChange: handleOptionValueInvoice,
  });

  const groupInvoice = getRootPropsInvoice();



  const [optionValueInventory, setOptionValueInventory] = useState("Total");
  const [chartDataInventory, setChartDataInventory] = useState({
    labels: validData ? labels : [],
    datasets: [
      {
        label: getLabelByType(type),
        data: validData ? dataValues : [],
        backgroundColor: validData ? generateColors(labels.length) : [],
        borderColor: validData ? generateBorderColors(generateColors(labels.length)) : [],
        borderWidth: 1,
      },
    ],
  });

  const handleOptionValueInventory = (value) => {
    setOptionValueInventory(value);
  };

  console.log("OPTIONS", options)

  useEffect(() => {
    if (type === "Inventory" && options) {
      let dataLabel = "";
      let dataSource = [];

      switch (optionValueInventory) {
        case "Price":
          dataLabel = "Price";
          dataSource = options.Price;
          break;
        case "Size":
          dataLabel = "Size";
          dataSource = options.Size;
          break;
        case "Quantity":
          dataLabel = "Quantity";
          dataSource = options.Quantity;
          break;
        default:
          dataLabel = "Price";
          dataSource = options.Price;
      }

      const newData = {
        labels: labels,
        datasets: [
          {
            label: dataLabel,
            data: dataSource,
            backgroundColor: generateColors(labels.length),
            borderColor: generateBorderColors(generateColors(labels.length)),
            borderWidth: 1,
          },
        ],
      };
      setChartData(newData);
    }
  }, [optionValueInventory, options, labels, type]);

  const optionsMapInventory = options ? Object.keys(options) : [];
  const { getRootProps: getRootPropsInventory, getRadioProps: getRadioPropsInventory } = useRadioGroup({
    name: "framework",
    defaultValue: optionsMapInventory[0],
    onChange: handleOptionValueInventory,
  });

  const groupInventory = getRootPropsInventory();


  return (
    <div style={{ display: "flex", width: "100%", height: "500px", margin: "30px 0" }}>
      <div style={{ flex: 1 }}>
        {type === "Staff" && (
          <Center>
            <HStack {...group}>
              {optionsMap.map((value) => {
                const radio = getRadioProps({ value });
                return (
                  <div key={value} onClick={() => handleOptionValue(value)}>
                    <RadioCard {...radio}>{value}</RadioCard>
                  </div>
                );
              })}
            </HStack>
          </Center>
        )}
        {type === "Invoice" && (
          <Center>
            <HStack {...groupInvoice}>
              {optionsMapInvoice.map((value) => {
                const radio = getRadioPropsInvoice({ value });
                return (
                  <div key={value} onClick={() => handleOptionValueInvoice(value)}>
                    <RadioCard {...radio}>{value}</RadioCard>
                  </div>
                );
              })}
            </HStack>
          </Center>
        )}
        {type === "Inventory" && (
          <Center>
            <HStack {...groupInventory}>
              {optionsMapInventory.map((value) => {
                const radio = getRadioPropsInventory({ value });
                return (
                  <div key={value} onClick={() => handleOptionValueInventory(value)}>
                    <RadioCard {...radio}>{value}</RadioCard>
                  </div>
                );
              })}
            </HStack>
          </Center>
        )}
        <Pie data={chartData} options={{ maintainAspectRatio: false }} />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            overflow: "auto",
            height: "600px",
            scrollbarColor: "gray transparent",
            scrollbarWidth: "thin",
          }}
        >
          <table style={{ width: "65%", borderCollapse: "collapse", marginBottom: "30px", fontSize:'11px' }}>
            <thead style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
              {type === "Sales" && (
                <tr style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Employee Name</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Sales</th>
                </tr>
              )}
              {type === "Membership" && (
                <tr style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Client Name</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Membership</th>
                </tr>
              )}
              {type === "Staff" && (
                <tr style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Employee Name</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    {optionValue === "Salary" ? "Salary" : "Sales"}
                  </th>
                </tr>
              )}
              {type === "Invoice" && (
                  <tr style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Employee Name</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>
                      {optionValueInvoice === "Total" 
                        ? "Total" 
                        : optionValueInvoice === "Paid" 
                        ? "Paid" 
                        : optionValueInvoice === "Balance" 
                        ? "Balance" 
                        : "Default Value"}
                    </th>
                  </tr>
                )}

              {type === "Inventory" && (
                <tr style={{ backgroundColor: "rgba(255, 206, 86, 0.2)" }}>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Product Name</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>
                    {optionValueInventory === "Price" 
                          ? "Price" 
                          : optionValueInventory === "Quantity" 
                          ? "Quantity" 
                          : optionValueInventory === "Size" 
                          ? "Size" 
                          : "Default Value"}
                  </th>
                </tr>
              )}
            </thead>

            <tbody style={{ maxHeight: "200px", height: "200px", overflowY: "auto", backgroundColor: "rgba(119, 221, 119, 0.2)" }}>
              {chartData.labels.map((label, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "8px" }}>{label}</td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {optionValueInventory!== 'Quantity' && optionValueInventory!== 'Size' ? "Rs." : ''}{" "}
                    {isNaN(chartData.datasets[0].data[index]) ? 0 : Math.floor(chartData.datasets[0].data[index])}
                  </td>
                </tr>
              ))}
              {optionValueInventory!== 'Quantity' && optionValueInventory!== 'Size' && <tr style={{ backgroundColor: "rgb(4 171 0 / 40%)" }}>
                <td style={{ border: "1px solid black", padding: "8px", fontWeight: "bold" }}>Total:</td>
                <td style={{ border: "1px solid black", padding: "8px", fontWeight: "bold" }}>
                  {optionValueInventory!== 'Quantity' && optionValueInventory!== 'Size' ? "Rs." : ''}{" "}
                  {chartData.datasets[0].data.reduce((acc, amount) => {
                    const value = typeof amount === "string" ? parseFloat(amount) : amount;
                    const numericValue = !isNaN(value) ? value : 0;
                    return Math.floor(acc + numericValue);
                  }, 0)}
                  /-
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
