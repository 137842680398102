import {
  Center,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Input,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import styled from 'styled-components'

import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Link as RouteLink} from 'react-router-dom'


export default function Employee() {
  // const dbpath1 = "http://localhost/backend/";
  const dbpath1 = "https://krubsalon.royalswebtech.com/backend/";
  const [Edata, setEData] = useState([]);
  const [filterData , setFilteredData] = useState([]);
  const loadEmployeeData = async () => {
    try {
      const response = await axios.get(dbpath1 + "getEmployeedata.php");
      const Employeedata = response.data.phpresult;
      setEData(Employeedata);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  useEffect(() => {
    loadEmployeeData(); // Call the loadData function when the component mounts
  }, []);
//   console.log(Edata);
const [searchTerm, setSearchTerm] = useState("");

useEffect(() => {
  setFilteredData(
    Edata.filter((user)=>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.ph_number.includes(searchTerm)
    )
  )
}, [searchTerm , Edata]);
console.log(Edata);

const OverlayOne = () => (
  <ModalOverlay
    bg='blackAlpha.300'
    backdropFilter='blur(10px) hue-rotate(90deg)'
  />
)

const { isOpen, onOpen, onClose } = useDisclosure();
const [overlay, setOverlay] = useState(<OverlayOne />);
const [selectedEmployee, setSelectedEmployee] = useState([]);

useEffect(() => {
  console.log("Selected Employee: ", selectedEmployee);
}, [selectedEmployee]);

const handleModal = (employee) => {
  const JSON_emp = JSON.stringify(employee);
  localStorage.setItem('EmployeeData', JSON_emp);
  localStorage.setItem('EmpRole', "Employee");

  setOverlay(<OverlayOne />);
  setSelectedEmployee(employee);
  window.location.href = '/employeeDetails';
};


  return (
    <Box>
      <Center>
        <Input
          placeholder="Search Employee name / Number "
          _placeholder={{ color: "gray" }}
          maxW={"50%"}
          color={'#121212'}
          border={"1px solid gray"}
          _hover={{ border: "1px solid gray" }}
          m={10}
          value={searchTerm}
          onChange={(e)=>{setSearchTerm(e.target.value)}}
        />
        <Button as={RouteLink} to={'/AddEmployee'} color={'black'} variant={'solid'} colorScheme='facebook'>Add Employee</Button>
      </Center>

      <Center>
        <Table
          variant="simple"
          size="lg"
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          maxW={"80%"}
        >
          <Thead>
            <Tr>
              <Th>Employee_ID</Th>
              <Th>Name</Th>
              <Th>Phone Number</Th>
              <Th>Type</Th>
              <Th>Experience</Th>
              <Th>DOB</Th>
              <Th>Salary</Th>
              <Th>Sales</Th>

            </Tr>
          </Thead>
          <Tbody>
            {filterData.map((item) => (
              <Tr p={10} onClick={() => { handleModal(item) }}>
                <Td color={"black"}>{item.id}</Td>
                <Td color={"black"}>{item.name}</Td>
                <Td color={"black"}>{item.ph_number}</Td>
                <Td color={"black"}>{item.category}</Td>
                <Td color={"black"}>{item.experience + " yrs"} </Td>
                <Td color={"black"} fontSize={"small"} fontFamily={"monospace"}>
                  {item.date_of_birth}
                </Td>
                <Td color={"black"}>{"₹ " + Number(item.salary).toFixed(0)}</Td>
                <Td color={"black"}>{"₹ " + Number(item.sales == '' ? 0 : item.sales)}</Td>

              </Tr>
            ))}
          </Tbody>
        </Table>
      </Center>


      {/* <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl'>
        {overlay}
        <ModalContent backgroundColor='white' width='600px'>
          <ModalHeader><h2 style={{color:'gray'}}>Employee Details</h2></ModalHeader>
          <ModalCloseButton color='black'/>
          <ModalBody>
          <ModalTable>
          <div className="row">
            <div className="column">
              <AvatarGroup className="avatar-group" spacing='1rem' width='150px' height='150px'>
                <Avatar className="avatar" sx={{ bgcolor: 'teal.500'}} width='130px' height='130px'/>
              </AvatarGroup>
              <div style={{color:'gray', display:'flex', justifyContent:'center', marginTop:'-25px'}}>
                <h3 style={{color:'gray'}}>{selectedEmployee.name}</h3>
              </div>
            </div>
            <div className="column" style={{width:'100%'}}>
              <table>
                <tbody>
                  <tr>
                    <td>ID :</td>
                    <td>{selectedEmployee.id}</td>
                  </tr>
                  <tr>
                    <td>Name :</td>
                    <td>{selectedEmployee.name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number :</td>
                    <td>{selectedEmployee.ph_number}</td>
                  </tr>
                  <tr>
                    <td>Category :</td>
                    <td>{selectedEmployee.category}</td>
                  </tr>
                  <tr>
                    <td>Experience :</td>
                    <td>{selectedEmployee.experience} yrs</td>
                  </tr>
                  <tr>
                    <td>Date of Birth :</td>
                    <td>{selectedEmployee.date_of_birth}</td>
                  </tr>
                  <tr>
                    <td>Salary :</td>
                    <td>₹ {Number(selectedEmployee.salary).toFixed(0)}</td>
                  </tr>
                  <tr>
                    <td>Sales :</td>
                    <td>₹ {Number(selectedEmployee.sales === '' ? 0 : selectedEmployee.sales)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </ModalTable>

          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} color='black'>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}



    </Box>
  );
}


const ModalTable = styled.div `
        .row {
          display: flex;
          /* gap: 20px; */
        }

        /* .column {
          flex: 1;
        } */

        table {
          width: 100%;
          border-collapse: collapse;
        }

        td,
        th {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
          color: gray;
        }

        tr:nth-child(even) {
          background-color: #f2f2f2;
        }

        tr:hover {
          background-color: #ddd;
        }

        .avatar-group {
          width: 150px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .avatar {
          width: 100px;
          height: 100px;
          background-color: teal;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 1.5rem;
        }
`;