import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import styled from "styled-components";
import Summary_Table from "./Summary_Table";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import ShowReports from "./ShowReports";
import axios from "axios";

import { DateRange } from "react-date-range";
import format from "date-fns/format";
import addDays from "date-fns/addDays";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const Reports = () => {
  const location = useLocation();
  const SalesLinks = [
    "All",
    "Sales",
    "Target",
    "Staff",
    "Invoice",
    "Membership",
    "Inventory",
    "Customer",
    "Expertise",
  ];

  const ReportCategories = {
    All: [
      "Daily Sales",
      "Customers",
      "Sales Summary",
      "Service sales",
      "Product sales",
      "Membership sales",
      "Package sales",
      "Vouchers",
      "Prepaid sales",
    ],
    Sales: [
      "Daily Sales",
      "Sales Summary",
      "Service sales",
      "Product sales",
      "Package sales",
      "Prepaid sales",
    ],
    Target: ["Target Sales Report", "Target Customer Reach"],
    Staff: ["Staff Performance", "Staff Attendance"],
    Invoice: ["Invoice Summary", "Pending Invoices", "Paid Invoices"],
    Membership: ["Membership sales"],
    Inventory: ["Inventory Levels", "Inventory Turnover"],
    Customer: ["Customers", "Customer Feedback", "Customer Demographics"],
    Expertise: ["Expertise Level Report", "Training Sessions"],
  };

  const [activeLink, setActiveLink] = useState("All");
  const [OpenChalendar, setOpenChalendar] = useState(false);

  const [selectedFormate, setSelectedFormate] = useState("Table");
  const [downloadFormate, setDownloadFormate] = useState(".PDF");

  const [selectedFilter, setSelectedFilter] = useState("Default");

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [date, setDate] = useState("");
  const [ToDate, setToDate] = useState("");

  // const dbpath1 = "http://localhost/backend/";
  const dbpath1 = "https://krubsalon.royalswebtech.com/backend/";
  const [Edata, setEData] = useState([]);
  const [labels, setLabels] = useState([]);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);

  const loadEmployeeData = async () => {
    try {
      const response = await axios.get(`${dbpath1}getClientData.php`);
      const Clientdata = response.data.phpresult;
      if (Array.isArray(Clientdata)) {
        setEData(Clientdata);
        const labelData = Clientdata.map((emp) => emp.name); // Replace 'labelProperty' with the actual property name
        setLabels(labelData);
        console.log("Client Data:", Clientdata);
      } else {
        console.error("Expected an array but got:", Clientdata);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    loadEmployeeData();
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    // return d.toLocaleDateString('en-CA'); // 'en-CA' gives the "YYYY-MM-DD" format
    return format(d, "MM/dd/yyyy");
  };

  // const onChange = (date, toDate) =>{
  //   const newdate = date.toString();
  //   const newToDate = toDate.toString();
  //   setDate(newdate);
  //   setToDate(newToDate);
  //   console.log("SELECTED DATE: ", newdate);
  //   setSelectedFilter("Custome");
  //   onClose();
  // }

  const onChange = (selectedDates) => {
    if (Array.isArray(selectedDates)) {
      const [start, end] = selectedDates;
      setDate(formatDate(start));
      setToDate(formatDate(end));
    } else {
      setDate(formatDate(selectedDates));
      setToDate("");
    }
    setSelectedFilter("Custome");
    onClose();
  };

  const selectTimeFilter = (filter) => {
    setSelectedFilter(filter);
  };

  const handleDayFilter = (e) => {
    const newValue = e.target.value;
    setSelectedFilter(newValue);
  };

  // useEffect(()=>{
  //   if (selectedFilter !== 'Custome') {
  //     const filter = {"selectedFilter":selectedFilter,"date": date}
  //     const stringFilter = JSON.stringify(filter)
  //     console.log("Filter", filter);
  //     localStorage.setItem("DataFilter", stringFilter)
  //   } else {
  //     const filter = {"selectedFilter":selectedFilter,"FromDate": date, "toDate": ToDate};
  //     const stringFilter = JSON.stringify(filter)
  //     console.log("Filter", filter);
  //     localStorage.setItem("DataFilter", stringFilter)
  //   }
  // }, [selectedFilter])

  const handleFilterByTimePeriod = (range) => {
    const filterRange = {
      fromTime: formatDate(range[0].startDate),
      toTime: formatDate(range[0].endDate),
    };
    console.log("start", range[0].startDate);
    const stringFilter = JSON.stringify(filterRange);
    localStorage.setItem("DataFilter", stringFilter);
  };
  useEffect(() => {
    handleFilterByTimePeriod(range);
  }, [range]);

  const refCloseOnClickOutside = useRef(null);
  const hideOnClickedOutside = (e) => {
    // console.log("ref",refCloseOnClickOutside.current)
    // console.log("refTrg",e.target)

    if (
      refCloseOnClickOutside.current &&
      !refCloseOnClickOutside.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };
  const hideOnEscape = (e) => {
    // console.log("key-->", e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickedOutside, true);
  }, []);

  // console.log("fd", formatDate('2024-02-29'))

  return (
    <div>
      <Box>
        <Center
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
              marginLeft: "20px",
            }}
          >
            <h2
              style={{
                color: "GrayText",
                textAlign: "left",
                fontSize: "17px",
                backgroundColor: "#e4eeff",
                padding: "5px 15px",
              }}
            >
              Reports
            </h2>
          </div>

          <Box
            color={"black"}
            borderBottom={"1px solid #d2d2d2"}
            w={"full"}
            height={"fit-content"}
            p={2}
            // display={'flex'}
            pos={"sticky"}
            top={0}
          >
            <Button
              color={"black"}
              borderRadius={"none"}
              borderBottom={
                location.pathname == "/reports/sales" ? "1px solid black" : ""
              }
            >
              Sales
            </Button>
            <Button
              color={"black"}
              borderRadius={"none"}
              opacity={0.5}
              as={Link}
              to={"/reports/trends"}
              borderBottom={
                location.pathname == "reports/trends" ? "1px solid black" : ""
              }
            >
              Trends
            </Button>

            <Button
              color={"black"}
              borderRadius={"none"}
              opacity={0.5}
              as={Link}
              to={"/reports/consolidate"}
              borderBottom={
                location.pathname == "reports/consolidate"
                  ? "1px solid black"
                  : ""
              }
            >
              Consolidate
            </Button>
          </Box>

          {/* Main Three Links Sales, Trends, Consolidate */}
          <Box
            color={"black"}
            w={"full"}
            height={"fit-content"}
            p={2}

            pos={"sticky"}
            top={0}
          >
            {/* Sub Links of Sales */}
            {SalesLinks
              ? SalesLinks?.map((link, index) => (
                  <Button
                    key={index}
                    color={"black"}
                    borderRadius={"none"}
                    borderBottom={
                      location.pathname == "/reports/sales" &&
                      (activeLink === link ? "3px solid gray" : "")
                    }
                    onClick={() => {
                      setActiveLink(link);
                    }}
                  >
                    {link}
                  </Button>
                ))
              : ""}
          </Box>

          {/* Data filtering Options */}
          <Box
            color={"black"}
            w={"100%"}
            height={"fit-content"}
            p={2}
            marginRight={"5px"}
            // display={'flex'}

            pos={"sticky"}
            top={0}
          >
            <ButtonGroup spacing="3" size="sm">
              <Button
                variant="outline"
                colorScheme={selectedFilter === "Default" ? "orange" : "blue"}
                value={"Default"}
                onClick={handleDayFilter}
              >
                Default
              </Button>

              <Button
                variant="outline"
                colorScheme={selectedFilter === "Day" ? "orange" : "blue"}
                value={"Day"}
                onClick={handleDayFilter}
              >
                Day
              </Button>

              <Button
                variant="outline"
                colorScheme={selectedFilter === "Week" ? "orange" : "blue"}
                value={"Week"}
                onClick={(e) => {
                  setSelectedFilter(e.target.value);
                }}
              >
                Week
              </Button>

              <Button
                variant="outline"
                maxWidth={"100%"}
                colorScheme={selectedFilter === "Month" ? "orange" : "blue"}
                value={"Month"}
                onClick={(e) => {
                  setSelectedFilter(e.target.value);
                }}
              >
                Month
              </Button>

              <Button
                colorScheme={selectedFilter === "Custome" ? "orange" : "blue"}
                variant="outline"
                width={"40%"}
                value={"Custome"}
                onClick={(e) => {
                  setOpen((open) => !open);
                  setSelectedFilter(e.target.value);
                }}
              >
                Custom Range
              </Button>

              {/* <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <PopoverTrigger>
          <Button colorScheme={selectedFilter === 'Custome' ? 'orange' : 'blue'}  variant='outline' width={'40%'}
          >Custom Range</Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Select Date: </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <Chalendar_content>
                <Calendar 
                  onChange={onChange} 
                  value={date ? new Date(date) : null} 
                  selectRange={true} 
                />
                </Chalendar_content>
              </PopoverBody>
              <PopoverFooter>


              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover> */}
            </ButtonGroup>{" "}
            <Menu>
              <MenuButton
                marginLeft={"30px"}
                px={4}
                py={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="1px"
                _hover={{ bg: "gray.400" }}
                _expanded={{ bg: "blue.400" }}
                _focus={{ boxShadow: "outline" }}
              >
                Salect Report Type <ChevronDownIcon marginLeft={"4px"} />
              </MenuButton>
              <MenuList
                color={"white"}
                maxHeight={"200px"}
                overflowY={"auto"}
                position={"relative"}
                zIndex={"999"}
              >
                {ReportCategories[activeLink] &&
                ReportCategories[activeLink].length > 0 ? (
                  ReportCategories[activeLink].map((report, index) => (
                    <MenuItem key={index}>{report}</MenuItem>
                  ))
                ) : (
                  <p color="orange">Nothing To Show !!</p>
                )}
              </MenuList>
            </Menu>
          </Box>


{/* showing selected time */}
          <Box
            color={"black"}
            w={"full"}
            height={"fit-content"}
            // display={'flex'}
            px={2}
            pos={"sticky"}
            top={0}
          >
            <input
              value={`From: ${format(
                range[0].startDate,
                "MM/dd/yyyy"
              )} To: ${format(range[0].endDate, "MM/dd/yyyy")}`}
              readOnly
              className="inputBox"
              onClick={() => setOpen((open) => !open)}
              style={{
                backgroundColor: "transparent",
                color: "gray",
                width: "250px",
                border: "1px solid #e7e7e7",
                borderRadius: "3px",
                padding: "0 4px",
                position: "relative",
                boxShadow: "0px 3px 4px -5px rgba(0,0,0,0.5)",
              }}
            />


{/* modal range chalendar */}
            {open && (
              <div
                className="calendarWrap"
                ref={refCloseOnClickOutside}
                style={{
                  border: "1px solid #ebebeb",
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  zIndex: "9999",
                  left: "21%",
                  top: "1px",
                }}
              >
                <DateRange
                  onChange={(item) => setRange([item.selection])}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  direction="vertical"
                  className="calendarElement"
                />
                <Button
                  colorScheme="orange"
                  width={"100%"}
                  padding={"0 8px"}
                  borderRadius={0}
                  height={"30px"}
                  onClick={(e) => {
                    setOpen((open) => !open);
                  }}
                >
                  Apply
                </Button>
              </div>
            )}
          </Box>


{/* Full Report Component */}
          <Box color={"black"} w={"full"} height={"fit-content"} px={2}>
            <ShowReports
              selectedFilter={selectedFilter}
              date={date}
              activeLink={activeLink}
            />
          </Box>
        </Center>
      </Box>
    </div>
  );
};

export default Reports;

const Chalendar_content = styled.div`
  .react-calendar__navigation__next-button {
    margin: 0 10px;
  }

  .react-calendar__navigation__prev-button {
    margin: 0 10px;
  }
`;
